// @ts-check
import { fork, select, put, call, delay, takeEvery, takeLatest, all } from "redux-saga/effects";
import { callServer } from "ReactApi/serverService";
import { basketActions } from "./basket-reducer";
import { errorActions } from "ReactReducers/Error/error-reducer";
import { productPickerActions } from "ReactReducers/ProductPicker/product-picker-reducer";
import * as tracking from "ReactComponents/Shared/Utils/tracking";
import * as priceConfigUtils from "ReactComponents/Shared/Utils/priceConfigUtils";
import { ADD_TO_BASKET_LOCALSTORAGE_KEY } from "../../constants";

// Here be dragons!
//   \\._,--._          _._
//   `--,   _ `.    .-'' _ `-. Y/
//   _,',\ \ `, |  |  ,-' `. `.`.\
//    /7  | |J .' J  (     |  |/,'
//    ,-.,'/ L  \.`-_ `-.   \  \_,(o._.-'\
//   ((`--'  `-.  \  `.  ) /`_/._-, ,--'`'
//    ,\        \  `-'  / ( |   //|-.`-._,
//    \/  -bf-   ``---''  '\\   '    `--,'

function* add(action) {
    try {
        const state = yield select();
        const configIndex = state.productPicker.get("salesConfigIndex") || 0;
        const periodIndex = state.productPicker.get("periodValueIndex") || 0;
        const classesValue = state.productPicker.get("classesValue");

        const priceConfiguration = action.payload.product.PriceConfigurations[configIndex];

        const basketItemFromLocalStorage = localStorage.getItem(ADD_TO_BASKET_LOCALSTORAGE_KEY);

        const basketItemData = {
            Isbn: action.payload.Id,
            Count: action.payload.amount,
            SalesFormCode: priceConfiguration.SalesFormCode,
            AccessFormCode: priceConfiguration.AccessFormCode,
            PeriodUnitValue: priceConfiguration.PeriodOptions[periodIndex].UnitValue,
            PeriodUnitTypeCode: priceConfiguration.PeriodOptions[periodIndex].UnitTypeCode,
            Classes: classesValue,
            BasketLineType: action.payload.BasketLineType || "",
            AddCountToExistingBasket: action.payload.AddCountToExistingBasket
        };

        /* Overwrite basket item with data from Local Storage if key "addToBasketOnLoginReturn" is present */
        if (basketItemFromLocalStorage) {
            const item = JSON.parse(basketItemFromLocalStorage);

            const salesConfigIndex = item.salesConfigIndex || 0;
            const priceConfig = item.product.PriceConfigurations[salesConfigIndex];

            basketItemData.Isbn = item.product.Id;
            basketItemData.Count = item.amount;
            basketItemData.SalesFormCode = priceConfig.SalesFormCode;
            basketItemData.AccessFormCode = priceConfig.AccessFormCode;
            basketItemData.PeriodUnitValue = priceConfig.PeriodOptions[item.periodValueIndex].UnitValue;
            basketItemData.PeriodUnitTypeCode = priceConfig.PeriodOptions[item.periodValueIndex].UnitTypeCode;
            basketItemData.Classes = item.classesValue;
        }

        const res = yield call(callServer, "/WebAPI/Feature/Basket/AddToBasket", "POST", basketItemData);

        if (res.Success) {
            const oldBasket = state.basket.basket;
            const productInOldBasket = oldBasket.BasketItems?.find(item => item.Isbn === action.payload.Id);
            const updatedBasket = res.Payload;
            const productInUpdatedBasket = updatedBasket.BasketItems?.find(
                item => item.Isbn === action.payload.Id
            );

            const productIsAlreadyInBasket = !!productInOldBasket;
            const productIsNotPhysical = priceConfiguration.SalesFormCode != 0; // This makes AccessFormCode == 1004 (enkeltbruger) + AllowQuantity trigger a product change!!
            const salesFormCodeChanged =
                productIsAlreadyInBasket &&
                priceConfiguration.SalesFormCode !== productInOldBasket.SalesFormCode;
            const accessFormCode =
                productIsAlreadyInBasket &&
                priceConfiguration.AccessFormCode !== productInOldBasket.AccessFormCode;
            const unitTypeCode =
                productIsAlreadyInBasket &&
                priceConfiguration.PeriodOptions[periodIndex].UnitTypeCode !==
                    productInOldBasket.PeriodUnitTypeCode;
            const unitValue =
                productIsAlreadyInBasket &&
                priceConfiguration.PeriodOptions[periodIndex].UnitValue !==
                    productInOldBasket.PeriodUnitValue;
            const licenseLabelChanged =
                productIsAlreadyInBasket &&
                priceConfiguration.LicenseLabel !== productInOldBasket.LicenseLabel;
            const classSelectionChanged =
                productIsAlreadyInBasket &&
                !priceConfigUtils.sameClassesSelected(
                    productInOldBasket.Classes,
                    priceConfiguration.ClassOptions.Options
                );

            // If the action product has the same id as a product in the basket
            // There is a possibility that there is a change in license/period/classes/other(?)
            // Which have different prices so we track as removing the product and adding the product
            // We check here by comparing the different properties that can have an impact on pricing
            if (
                productIsAlreadyInBasket &&
                productIsNotPhysical &&
                (salesFormCodeChanged ||
                    accessFormCode ||
                    unitTypeCode ||
                    unitValue ||
                    licenseLabelChanged ||
                    classSelectionChanged)
            ) {
                // Product has changed!
                // Track a removal AND addition of a product
                //console.log("Product has changed!");

                yield call(tracking.removeFromBasket, {
                    item: {
                        product: productInOldBasket,
                        amount: productInOldBasket.CalculatedCount,
                        inspectionCopyLabel: oldBasket.InspectionCopyLabel
                    }
                });
                yield call(tracking.addToBasket, {
                    item: {
                        product: productInUpdatedBasket,
                        amount: productInUpdatedBasket.CalculatedCount,
                        inspectionCopyLabel: oldBasket.InspectionCopyLabel
                    }
                });
            } else {
                // Before updating basket:
                // Determine if quantity decreased or increased
                // Call applicable tracking method with the change in quantity
                //console.log("Product is being added/removed or ONLY the quantity changed on a physical product!");
                const quantityChange = updatedBasket.TotalItemCount - oldBasket.TotalItemCount;

                // Use calculated count to track
                let calculatedCountChange = productInUpdatedBasket.CalculatedCount;
                if (productInOldBasket) {
                    calculatedCountChange -= productInOldBasket.CalculatedCount;
                }

                // Track either removal OR addition of a product
                if (quantityChange > 0) {
                    yield call(tracking.addToBasket, {
                        item: {
                            product: productInUpdatedBasket,
                            amount: calculatedCountChange,
                            inspectionCopyLabel: oldBasket.InspectionCopyLabel
                        }
                    });
                } else if (quantityChange < 0) {
                    yield call(tracking.removeFromBasket, {
                        item: {
                            product: productInUpdatedBasket,
                            amount: Math.abs(calculatedCountChange),
                            inspectionCopyLabel: oldBasket.InspectionCopyLabel
                        }
                    });
                }
            }

            yield put(basketActions.basketAddSuccess(res.Payload));

            //update productpicker ui if it exists
            const work = state.productPicker.work;
            if (work) {
                yield put(productPickerActions.getUpdatedWorkProducts(work.Id));
            }
            //update productpicker if basketItemFromLocalStorage exists
            if (basketItemFromLocalStorage) {
                const item = JSON.parse(basketItemFromLocalStorage);

                yield put(productPickerActions.getUpdatedWorkProducts(item.workId));
            }
        } else {
            yield put(errorActions.addError(res.ErrorMessage));
        }
    } catch (error) {
        console.error(error);
        yield put(errorActions.addError(error.message));
    } finally {
        yield put(basketActions.basketAddComplete(action.payload));
    }
}

function* remove(action) {
    try {
        const state = yield select();
        const res = yield call(callServer, "/WebAPI/Feature/Basket/RemoveFromBasket", "POST", {
            Id: action.payload
        });
        if (res.Success) {
            const basket = state.basket.basket;
            const product = basket.BasketItems.find(item => item.BasketLineId === action.payload);

            yield call(tracking.removeFromBasket, {
                item: {
                    product,
                    amount: product.CalculatedCount,
                    inspectionCopyLabel: basket.InspectionCopyLabel
                }
            });
            yield put(basketActions.basketAddSuccess(res.Payload));

            //update productpicker ui if it exists
            const work = state.productPicker.work;
            if (work) {
                yield put(productPickerActions.getUpdatedWorkProducts(work.Id));
            }
        } else {
            yield put(errorActions.addError(res.ErrorMessage));
        }
    } catch (error) {
        console.error(error);
        yield put(errorActions.addError(error.message));
    } finally {
        yield put(basketActions.basketRemoveComplete(action.payload));
    }
}

function* setQuantity(action) {
    // setQuantity is only called when the quantity of a physical product is changed directly in the basket
    const state = yield select();
    try {
        const res = yield call(callServer, "/WebAPI/Feature/Basket/SetQuantity", "POST", {
            Id: action.payload.id,
            Quantity: action.payload.quantity
        });
        if (res.Success) {
            // Before updating basket:
            // Determine if quantity decreased or increased
            // Call applicable tracking method with the change in quantity
            const basket = state.basket.basket;
            const productInBasket = basket.BasketItems.find(item => item.BasketLineId === action.payload.id);

            const oldQuantity = productInBasket.Count;
            const quantityChange = action.payload.quantity - oldQuantity;

            // Track either removal OR addition of a product
            if (quantityChange > 0) {
                yield call(tracking.addToBasket, {
                    item: {
                        product: productInBasket,
                        amount: quantityChange,
                        inspectionCopyLabel: basket.InspectionCopyLabel
                    }
                });
            } else if (quantityChange < 0) {
                yield call(tracking.removeFromBasket, {
                    item: {
                        product: productInBasket,
                        amount: Math.abs(quantityChange),
                        inspectionCopyLabel: basket.InspectionCopyLabel
                    }
                });
            }

            yield put(basketActions.basketAddSuccess(res.Payload));
        } else {
            yield put(errorActions.addError(res.ErrorMessage));
        }
    } catch (error) {
        console.error(error);
        yield put(errorActions.addError(error.message));
    } finally {
        yield put(basketActions.basketSetQuantityComplete(action.payload));
    }
}

function* setClassesValue(action) {
    // setClassesValue is called when the classes on a schoole or class license is changed directly in the basket
    const state = yield select();
    try {
        const res = yield call(callServer, "/WebAPI/Feature/Basket/UpdateClasses", "POST", {
            Id: action.payload.id,
            Classes: action.payload.classes
        });
        if (res.Success) {
            const oldBasket = state.basket.basket;
            const productInOldBasket = oldBasket.BasketItems.find(
                item => item.BasketLineId === action.payload.id
            );
            const updatedBasket = res.Payload;
            const productInUpdatedBasket = updatedBasket.BasketItems.find(
                item => item.BasketLineId === action.payload.id
            );

            // Track a removal AND addition of a product
            yield call(tracking.removeFromBasket, {
                item: {
                    product: productInOldBasket,
                    amount: productInOldBasket.CalculatedCount,
                    inspectionCopyLabel: oldBasket.InspectionCopyLabel
                }
            });
            yield call(tracking.addToBasket, {
                item: {
                    product: productInUpdatedBasket,
                    amount: productInUpdatedBasket.CalculatedCount,
                    inspectionCopyLabel: oldBasket.InspectionCopyLabel
                }
            });

            yield put(basketActions.basketAddSuccess(res.Payload));
        } else {
            yield put(errorActions.addError(res.ErrorMessage));
        }
    } catch (error) {
        console.error(error);
        yield put(errorActions.addError(error.message));
    } finally {
        yield put(basketActions.basketSetClassesValueComplete(action.payload));
    }
}

function* submitDiscountCode(action) {
    try {
        yield delay(500);
        const res = yield call(
            callServer,
            "/WebAPI/Feature/Basket/AddPromotionToBasket?discountCode=" +
                action.payload.formInfo.PromotionCode,
            "POST"
        );

        if (res.Success) {
            yield put(basketActions.discountCodeSuccess(res.Payload));
            action.payload.resolve(res);
        } else {
            yield put(basketActions.discountCodeError());

            if (!res.Payload) {
                yield put(errorActions.addError(res.ErrorMessage));
            }
            action.payload.resolve(res);
        }
    } catch (error) {
        console.error(error);
        yield put(errorActions.addError(error.message));
        action.payload.reject(error);
    } finally {
        yield put(basketActions.discountCodeComplete());
    }
}

function* removeDiscountCode() {
    try {
        yield delay(500);
        const res = yield call(callServer, "/WebAPI/Feature/Basket/RemovePromotionFromBasket", "POST");

        if (res.Success) {
            yield put(basketActions.discountCodeSuccess(res.Payload));
        } else {
            yield put(basketActions.discountCodeError());

            if (!res.Payload) {
                yield put(errorActions.addError(res.ErrorMessage));
            }
        }
    } catch (error) {
        console.error(error);
        yield put(errorActions.addError(error.message));
    } finally {
        yield put(basketActions.discountCodeComplete());
    }
}

export default function* basketSaga() {
    yield all([
        fork(function* () {
            yield takeEvery(basketActions.basketAdd.type, add);
        }),
        fork(function* () {
            yield takeLatest(basketActions.basketRemove.type, remove);
        }),
        fork(function* () {
            yield takeLatest(basketActions.basketSetQuantity.type, setQuantity);
        }),
        fork(function* () {
            yield takeLatest(basketActions.basketSetClassesValue.type, setClassesValue);
        }),
        fork(function* () {
            yield takeLatest(basketActions.discountCodeSubmit.type, submitDiscountCode);
        }),
        fork(function* () {
            yield takeLatest(basketActions.discountCodeRemove.type, removeDiscountCode);
        })
    ]);
}
